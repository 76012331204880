<template>
	<v-dialog v-model="loginState"
		max-width="600"
	>
		<login-card />
	</v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import LoginCard from "@/components/LoginCard.vue";

export default {
	name: "LoginDialog",
	components: {LoginCard},
	computed: {
		...mapGetters(["loginState"])
	}
}
</script>

<style scoped lang="scss">
@import "src/styles/fonts.scss";
.auth-text-btn {
	color: darkgrey !important;
	height: 30px !important;
	::v-deep.v-btn__content {
		padding: 4px;
		font-size: 14px;
	}
}
.auth-text-btn:hover {
	color: dimgrey !important;
}
</style>
