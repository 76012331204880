<template>
	<v-app-bar
		app flat
		class="white"
	>
		<v-app-bar-nav-icon
			v-if="$route.name !== 'Login'"
			@click="drawer = !drawer">
			<v-icon>mdi-menu</v-icon>
		</v-app-bar-nav-icon>

		<v-app-bar-title v-if="$route.name === 'Login'" class="px24 font-bold">Haajir Administration</v-app-bar-title>

		<v-spacer />
		<v-btn
			v-if="isLoginRoute && !currentUser"
			outlined color="primary" rounded
			@click="SET_LOGIN_STATE(true)"
		>
			Login
		</v-btn>
		<v-btn
			v-if="isLoginRoute && currentUser"
			outlined color="primary" rounded
			@click="logout"
		>
			Logout
		</v-btn>
		<login-dialog />
	</v-app-bar>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import LoginDialog from "@/components/Dialogs/LoginDialog.vue";
import Snack from "@/mixins/Snack.js";

export default {
	name: "TheAppBar",
	components: {LoginDialog},
	mixins: [Snack],
	props: {},
	computed: {
		drawer: {
			get() {
				return this.$store.getters.drawerState
			},
			set(val) {
				this.SET_DRAWER_STATE(val)
			}
		},
		...mapGetters(["currentUser"]),
		isLoginRoute() {
			return this.$route.name !== "Login"
		}
	},
	created() {
		if (this.$helper.getAccessToken()) {
			this.SET_CURRENT_USER({ username: this.$helper.getCurrentUser() })
		}
	},
	methods: {
		...mapMutations(["SET_DRAWER_STATE", "SET_LOGIN_STATE", "SET_CURRENT_USER"]),
		logout() {
			this.SET_CURRENT_USER(null)
			localStorage.clear()
			this.openSnack({text: "Logged out successfully", color: "success"})
			this.$router.push({name: "Login"})
		}
	}
}
</script>

<style scoped>

</style>
